<template>
  <v-row class="custom d-flex justify-center align-center py-8 px-6">
    <v-combobox
      v-if="showSelectTeams"
      v-model="selectedTeams"
      v-on-clickaway="closeTeamsMenu"
      :items="teams"
      item-text="fullTeamName"
      outlined
      dense
      hide-details
      multiple
      :label="$t('common.team')"
      append-icon="mdi-chevron-down"
      color="primary"
      class="mx-3"
      style="width: 5rem;"
      :menu-props="{ closeOnContentClick: true }"
      @change="isTeamsMenuOpen = true; updateSearch()"
      @mousedown="isTeamsMenuOpen = true"
    >
      <template #prepend-item>
        <v-list-item
          tabindex="0"
          :aria-selected="isAllTeamsSelected"
          role="option"
          @click="toggleSelectAllTeams"
        >
          <v-list-item-action>
            <v-simple-checkbox :value="isAllTeamsSelected" :ripple="false" @click="toggleSelectAllTeams" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="$t('common.selectAll')" />
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #selection="{ item, index }">
        <!-- open dropdown -->
        <v-chip
          v-if="selectedTeams.some(team => team.id === item.id) && isTeamsMenuOpen"
          close
          color="primary"
          outlined
          @click:close="selectedTeams = selectedTeams.filter(team => team.id !== item.id)"
        >
          <span>
            {{ item.fullTeamName }}
          </span>
        </v-chip>
        <!-- closed dropdown -->
        <span
          v-else-if="index === 0 && !isTeamsMenuOpen"
          class="text-truncate"
          style="max-width: 15rem;"
        >
          {{ item.fullTeamName }}
        </span>
        <span
          v-else-if="index === 1 && !isTeamsMenuOpen"
          class="grey--text text-caption"
        >
          (+{{ selectedTeams.length - 1 }})
        </span>
      </template>
    </v-combobox>
    <!-- https://owqlo.atlassian.net/browse/OW-3537 -->
    <!-- <v-combobox
      v-model="selectedRoles"
      v-on-clickaway="closeRolesMenu"
      :items="roles"
      item-text="name"
      outlined
      dense
      hide-details
      multiple
      :label="$t('common.roles')"
      append-icon="mdi-chevron-down"
      color="primary"
      class="mx-3"
      style="width: 5rem;"
      :menu-props="{ closeOnContentClick: true }"
      @change="isRolesMenuOpen = true; updateSearch()"
      @mousedown="isRolesMenuOpen = true"
    >
      <template #prepend-item>
        <v-list-item
          tabindex="0"
          :aria-selected="isAllRolesSelected"
          role="option"
          @click="toggleSelectAllRoles"
        >
          <v-list-item-action>
            <v-simple-checkbox :value="isAllRolesSelected" :ripple="false" @click="toggleSelectAllRoles" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="$t('common.selectAll')" />
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #selection="{ item, index }">
        <!-- open dropdown -->
    <!-- <v-chip
          v-if="selectedRoles.some(role => role.id === item.id) && isRolesMenuOpen"
          close
          color="primary"
          outlined
          @click:close="selectedRoles = selectedRoles.filter(role => role.id !== item.id)"
        >
          <span>
            {{ item.name }}
          </span>
        </v-chip>
        <!-- closed dropdown -->
    <!-- <span
          v-else-if="index === 0 && !isRolesMenuOpen"
          class="text-truncate"
          style="max-width: 15rem;"
        >
          {{ item.name }}
        </span>
        <span
          v-else-if="index === 1 && !isRolesMenuOpen"
          class="grey--text text-caption"
        >
          (+{{ selectedRoles.length - 1 }})
        </span>
      </template>
    </v-combobox> -->
    <v-menu
      v-model="isDatePickerOpen"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="params.date"
          :label="$t('project.academy.posts.creation')"
          append-icon="event"
          outlined
          readonly
          dense
          hide-details="auto"
          v-bind="attrs"
          class="mx-3"
          style="width: 5rem;"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="params.date"
        @input="updateSearch"
      >
        <template #default class="d-flex flex-row justify-center align-center py-0">
          <v-btn
            color="primary"
            class="ml-auto mt-0"
            style="width: 100%;"
            @click="$emit('resetDate'); params.date = null;"
          >
            <span
              class="mr-1"
            >
              {{ $t('common.resetSearch') }}
            </span>
            <v-icon
              size="32"
            >
              mdi-delete-outline
            </v-icon>
          </v-btn>
        </template>
      </v-date-picker>
    </v-menu>
    <v-text-field
      v-model="params.text"
      :label="$t('common.search')"
      outlined
      dense
      hide-details
      append-icon="search"
      class="mx-3"
      style="width: 5rem;"
      @change="updateSearch"
    />
    <v-btn
      v-if="showPublishButton"
      color="primary"
      type="submit"
      class="mx-3"
      dark depressed
      @click.prevent="$emit('togglePublish')"
      v-text="$t('common.publish')"
    />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'PostsFilter',
  mixins: [clickaway],
  props: {
    showPublishButton: {
      type: Boolean,
      default: true,
    },
    showSelectTeams: { type: Boolean, default: true },
  },
  data() {
    return {
      isDatePickerOpen: false,
      isTeamsMenuOpen: false,
      isRolesMenuOpen: false,
      selectedTeams: null,
      selectedRoles: null,
      params: {
        teams: null,
        roles: null,
        date: null,
        text: null,
      },
    }
  },
  computed: {
    ...mapGetters({ teams: 'academy/teams' }),
    roles() {
      // TODO: add Manager + DOC (director of coaching) when added to ddbb models
      return [
        {
          id: 'coach',
          name: this.$t('user.roles.coaches'),
        },
        {
          id: 'player',
          name: this.$t('user.roles.players'),
        },
        {
          id: 'parent',
          name: this.$t('user.roles.parents'),
        },
        {
          id: 'manager',
          name: this.$t('user.roles.managers'),
        },
        {
          id: 'submanager',
          name: this.$t('user.roles.submanagers'),
        },
      ]
    },
    isAllTeamsSelected({ teams, selectedTeams }) {
      return selectedTeams?.length === teams?.length
    },
    isAllRolesSelected({ roles, selectedRoles }) {
      return selectedRoles?.length === roles?.length
    },
  },
  watch: {
    selectedTeams(value) {
      this.params.teams = value?.map(team => team.id)
    },
    selectedRoles(value) {
      this.params.roles = value?.map(role => role.id)
    },
  },
  methods: {
    toggleSelectAllTeams() {
      const { teams, isAllTeamsSelected } = this
      if (isAllTeamsSelected) this.selectedTeams = null
      else this.selectedTeams = teams
    },
    toggleSelectAllRoles() {
      const { roles, isAllRolesSelected } = this
      if (isAllRolesSelected) this.selectedRoles = null
      else this.selectedRoles = roles
    },
    updateSearch() {
      this.$emit('updateSearch', this.params)
    },
    closeTeamsMenu() {
      this.isTeamsMenuOpen = false
    },
    closeRolesMenu() {
      this.isRolesMenuOpen = false
    },
  },
}
</script>
